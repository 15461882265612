@import "./code.css";
@import "./page.css";
@import "./level.css";
@import "./hubspot.css";
@import "./stats.css";
@import url(https://fonts.googleapis.com/css?family=Lato:100,200,300,400,500,700,400italic,700italic);

/* General */

:root {
  --pink: #eddfd6;
  --black: #222222;
  --primary-color: var(--pink);
  --secondary-color: var(--black);
  margin-left: 15%;
  margin-right: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--primary-color);
  border-radius: 8px 0px 0px 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 8px 0px 0px 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
  border-radius: 8px 0px 0px 8px;
}

.unfitScreenSize {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0%;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  font-size: 14px;
}

ul {
  padding-left: 0;
}

.logo {
  color: var(--secondary-color);
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  padding-top: 10px;
}

/* .christmas-hat {
  color: var(--secondary-color);
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  margin-top: -15%;
} */


.lines {
  margin-bottom: 4%;
  margin-top: 2%;
}

.footer {
  background-color: var(--secondary-color);
}

.main,
.home,
.navi-button,
.other {
  font-family: "helvetica", sans-serif;
  font-weight: 400;
  font-size: 16 px;
  color: var(--primary-color);
  text-decoration: none;
}

.element-in-row {
  margin-left: 1em;
}

hr {
  margin-top: 10px;
}

h2 {
  font-size: 14px;
  padding: 1% 10% 0% 10%;
  color: var(--secondary-color);
  font-family: Helvetica, sans-serif;
  text-align: center;
  line-height: 1.5;
}

button {
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  padding: 4px 9px;
  color: var(--primary-color);
  font-weight: bold;
  cursor: pointer;
}

.button-actions {
  margin-left: 1%;
  font-size: larger;
}

button :hover {
  background-color: var(--primary-color);
}

.header-container {
  display: flex;
  flex-direction: column;
}

.top {
  border: none;
  border-top: 1px solid var(--secondary-color);
  height: 1px;
  align-items: center;
}


.top-banner {
  display: flex;
  gap: 10%;
  align-items: center;
  justify-content: space-between;
   width: 100%;
  height: 80px;
  border-radius: 6px;
  border: 2px solid var(--secondary-color);
  margin-bottom: 4%;
  margin-top: 2%;
  padding: 2%
}

.top-banner-text {
  display: block;
  overflow-wrap: break-word;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}

.header-ul {
  padding: 0px 0px;
  margin-bottom: 0;
}

.hiring {
  color: var(--primary-color);
}

nav li {
  list-style: none;
}

.nav-links {
  display: inline-block;
  padding: 0px;
}

.nav-links a {
  color: var(--secondary-color);
  text-decoration: none;
  font-family: helvetica, sans-serif;
}

.nav-links button i:hover{
  outline: none;
  background: transparent;
}

.icon-buttons {
  height: 35px;
  font-size: larger;
  margin-top: -7px;
}

.dropdown {
  float: right;
  font-size: x-large;
  padding: 4px;
  list-style-type: none;
}

.dropdown-content {
  display: none;
  background-color: var(--secondary-color);
  width: 11em;
  border: solid 15px var(--secondary-color);
  border-radius: 6px;
  position: absolute;
  margin-left: -10em;
  z-index: 10;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  float: none;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 4px;
  padding: 6px 8px;
  margin-bottom: 5%;
  text-decoration: none;
  font-style: bold;
  display: block;
  font-size: 12px;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-radius: 6px;
}

.dropdown-content .contr {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: var(--primary-color) solid 1px;
  border-radius: 6px;
  font-weight: bold;
}

.the-ethernaut {
  width: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  margin-bottom: 2em;
}

.deploy-window-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 4;
  display: none;
}

.deploy-window {
  position: fixed;
  top: 20%;
  width: 40%;
  left: 30%;
  right: 30%;
  display: inherit;
  background-color: var(--primary-color);
  z-index: 5;
  padding: 2% 2% 2% 2%;
  box-shadow: black 0px 0px 60px;
  border: 2px solid var(--secondary-color);
  border-radius: 6px;
}

.deploy-window ul {
  padding-left: 1em;
}

.deploy-window h1 {
  text-align: center;
}

.choice-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1em;
  margin-bottom: 1em;
}

.deploy-note {
  margin-bottom: 0px;
}

.arrow {
  fill: var(--secondary-color);
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3%;
}

.titles ul {
  position: relative;
  text-align: center;
  align-items: center;
  padding: 0px 0px;
  margin-top: 1%;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.game {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 2em;
}

.content_img {
  word-wrap: break-word;
  position: relative;
  width: 80px;
  height: 80px;
  float: center;
  margin-left: 5px;
  margin-bottom: 5px;
  overflow: hidden;
}

.level-tile {
  border-radius: 6px;
}

.source-code {
  margin-bottom: 2em;
  width: 800px;
}

.content_img div {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 2px;
  right: 1px;
  background: var(--secondary-color);
  font-weight: bold;
  color: var(--primary-color);
  text-align: center;
  height: 78px;
  word-break: break-all;
  font-family: Arial, Helvetica, sans-serif;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear;
}

.content_img img {
  width: 80px;
  height: 80px;
}

.content_img:hover {
  cursor: pointer;
}

.content_img:hover div {
  width: 78px;
  visibility: visible;
  opacity: 100;
  border-radius: 6px;
}

.Description {
  width: 85%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 1% 0% 5% 0%;
}

.row2 {
  display: inline-flex;
  margin-left: 4%;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}
.author-section-border{
  border: none !important;
}
.author-section{
  width: 60%;
  margin-left: 20%;

  padding: 20px 20px 20px 20px;
  border: 2px solid var(--secondary-color);

  margin-bottom: 2em;
  text-align: left;
  border-radius: 6px;
}
footer {
  width: 100vw;
  position: fixed;
  left: 0;
  padding: 10px 10px 0px 10px;
  bottom: 0;
  height: 25px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  padding: 5px;
}

.deploy-footer {
  width: 100vw;
  position: fixed;
  left: 0;
  padding: 10px 10px 0px 10px;
  bottom: 0;
  height: 30px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.hljs-override {
  background: var(--secondary-color);
}

/* Boxes */

.boxes {
  padding: 20px 20px 20px 20px;
  border: 2px solid var(--secondary-color);
  width: 800px;

  margin-bottom: 2em;
  text-align: left;
  border-radius: 6px;
}

.boxes-code {
  padding: 15px 0px 15px 0px;
}

.boxes section {
  text-align: left;
}

.boxes .emptySection {
  background-color: var(--primary-color);
  margin: 0;
  border: 0;
  border-style: none;
}

.boxes h3 {
  text-align: left;
  font-weight: bolder;
}

.toggle {
  margin-right: 10px;
  appearance: none;
  vertical-align: middle;
  width: 30px;
  height: 15px;
  display: inline-block;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: var(--secondary-color);
  transition: background-color ease 0.3s;
}

.toggle:before {
  content: "";
  display: block;
  position: relative;
  z-index: 2;
  width: 10px;
  height: 10px;
  background: var(--primary-color);
  left: 2px;
  top: 2.5px;
  border-radius: 50%;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--primary-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

.toggle:checked:before {
  left: 16px;
}

.progress-bar-wrapper {
  display: none;
}


/* --------- styles for multi dropdowns --------------*/
.multi-dropdown{
  padding: 0% 0px;
}

.multi-dropdown__icon{
  color: var(--primary-color);
  background-color: var(--secondary-color);
  padding: 4px 9px;
  border-radius: 5px;
  cursor: pointer;
  transition: 500ms;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 20px;
}

.multi-dropdown__icon:hover{
  opacity: 0.90;
}

.multi-dropdown__dropdown{
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: solid 10px var(--secondary-color);
  width: 18em;
  border-radius: 6px;
  position: absolute;
  margin-left: -15.8em;
  z-index: 10;
  list-style-type: none;
  margin-top: 5px;
}

.multi-dropdown__dropdown.--closed{
  display: none;
}

.multi-dropdown__dropdown > div{
  background-color: var(--primary-color);
  color:  var(--secondary-color);
  margin-bottom: 7px;
  transition: 300ms;
}
.multi-dropdown__dropdown > div:last-of-type{
  margin-bottom: 0;
}

.multi-dropdown__dropdown div:hover{
  opacity: 0.95;
}

.single-dropdown{
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.single-dropdown.--hidden{
  display: none;
}

.single-dropdown p {
  font-weight: 500;
  text-transform: capitalize;
  display: flex;
  gap: 10%;
  padding-left: 10px;
  padding-top: 3px;
  cursor: pointer;
  margin-bottom: 0;
  align-items: center;
}
.single-dropdown > div {
  margin-top: 15px;
  text-align: left;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.single-dropdown > div.hide{
  display: none;
}

.dropdown-pill{
  background-color:  var(--secondary-color);
  color:  var(--primary-color);
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  line-height: 16px;
}

.dropdown-pill a, .dropdown-pill span{
  text-decoration: none;
  color:  var(--secondary-color);
}

.dropdown-pill div{
  display: flex;
  align-items: center;
}

.dropdown-pill.--left{
  text-align: left;
}


.single-dropdown a{
  text-decoration: none;
  color:  var(--primary-color);;
}

.toggle.--small {
  width: 35px;
  height: 20px;
}

.toggle:before {
  width: 15px;
  height: 15px;
}

.filled-icon{
  background-color: var(--secondary-color);
  color: var(--primary-color);
  width: fit-content;
  padding: 4px 8px;
  border-radius: 5px;
}

.filled-icon svg{
  fill: var(--primary-color);
  display: inline-block;
  height: 13px;
}

.logo-container {
  margin-left: -4em;
}

/* --------- styles for multi dropdowns --------------*/
@media only screen and (max-width: 1029px){
  :root {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.leader-icon {
  width: 10px;
  cursor: pointer;
  fill: var(--secondary-color);
  margin-right: 0px;
}

.tooltip-container {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  padding:5px 10px;
  border-radius: 5px;
}

@media only screen and (max-width: 956px) {
  .multi-dropdown__dropdown{
    margin-left: -15.8em;
  }
  
}


@media only screen and (max-width: 885px) {
  .appcontainer{
    width: 100vw;
  }

  .top-banner-text {
    font-size: smaller;
  }

  .header-container {
    align-items: center;
  }

  .top-banner {
    height: 100px;
    width: 95%;
  }

  .multi-dropdown{
    padding: 4px 9px;
  }

  .header-ul{
    display: flex;
    margin-right: 20px;
  }
  .helpcontainer{
    width: calc(100vw - 40px);
  }
  .header-ul > .dropdown.chains{
    display: none;
  }

  /* .toggle {
    display: none;
  } */

  .help-button {
    display: none;
  }

  .hiring-button {
    display: none;
  }

  .progress-bar-wrapper {
    display: none;
  }

  .boxes {
    width: 100%;
  }

  .logo-container {
    margin-left: 2em;
  }

  .source-code {
    /* add a padding of 40px ;> */
    width: calc(100vw - 40px);
  }

  .author-section-border{
    padding: 0;
  }

  .author-section {
    width: 100%;
    margin: 0;
  }

  .game {
    justify-content: center;
  }

  .the-ethernaut {
    width: 60%;
  }
}

/* 404 Page Not found*/
.page-not-found-container {
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

 .page-not-found-title{
    margin-top: 3rem;
 }

 .page-not-found-text{
  margin-top: 1rem;
}